var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('table-component',{attrs:{"headers":_vm.tableHeaders,"height":'calc(100vh - 64px - 124px - 30px)',"sort-by":"name","important-keys":_vm.importantKeys,"items":_vm.companies,"onRowClick":_vm.onRowClicked},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overflow-handle"},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center overflow-handle"},[_c('span',[_vm._v(" "+_vm._s(item.address.street)+" "+_vm._s(item.address.houseNo)+" ")])])]}},{key:"postalCode",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"align-center overflow-handle"},[_vm._v(" "+_vm._s(item.address.postalCode)+" ")])]}},{key:"city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.city)+" ")]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.countryFlags[item.address.country])+" "+_vm._s(_vm.$t(("GENERAL.COUNTRIES." + (item.address.country.toUpperCase()))))+" ")]}},{key:"users",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-3 font-weight-bold user-details"},[(item.usersCount)?_c('div',{class:_vm.getCompanyColor(item, true)},[_vm._v(" "+_vm._s(Math.round((item.usersCount / item.maxUsers) * 100))+"% ")]):_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" - ")])]),_c('v-progress-linear',{attrs:{"background-color":"on-background-variant-2","height":"8","color":_vm.getCompanyColor(item, false),"value":(item.usersCount / item.maxUsers) * 100}}),_c('div',{staticClass:"ml-3 user-details d-flex justify-end"},[_vm._v(" "+_vm._s(item.usersCount)+"/"+_vm._s(item.maxUsers)+" ")])],1)]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center justify-start"},[_c('v-btn',{staticClass:"status-btn",class:{'success--text': item.active, 'error--text': !item.active},attrs:{"color":item.active ? _vm.getColor('decent-success') : _vm.getColor('decent-error'),"elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.onCompanyStatusToggleClick(item)}}},[_vm._v(" "+_vm._s(item.active ? _vm.$t('GENERAL.ACTIVE') : _vm.$t('GENERAL.INACTIVE'))+" ")])],1)]}}]),model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),(_vm.showChangeStatusDialog)?_c('v-dialog',{attrs:{"max-width":"340"},model:{value:(_vm.showChangeStatusDialog),callback:function ($$v) {_vm.showChangeStatusDialog=$$v},expression:"showChangeStatusDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('COMPANY.STATUS_DIALOG.TITLE')))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('COMPANY.STATUS_DIALOG.TEXT', _vm.isActive))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":function($event){return _vm.onCompanyChangeStatus(_vm.selectedCompany)}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.OK'))+" ")]),_c('v-btn',{staticClass:"custom-border",attrs:{"text":""},on:{"click":function($event){_vm.showChangeStatusDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.CANCEL'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }